<template>
    <!-- 同系列推荐 -->
    <!-- 自营产品列表 -->
    <div>
        <div class="goods-list clearfix">
            <div class="goods-item" v-for="(goods, index) in data.list" :key="index" @click="$router.pushToTab({ path: '/sku-' + goods.goods_id })">
                <el-image :src="$img(goods.goods_image)" fit="cover"></el-image>
                <div class="goods-info" @click.stop>
                    <div class="goods-row row1">
                        <h2 style="width: 160px;padding-right: 0px;">{{ goods.goods_name }}</h2>
                        <div class="goods-collect activited" style="cursor: pointer;">
                            <span class="goods-collect-btn"  @click="editCollection(goods,index)">
                                <!-- <img :src="collectIcon" v-if="!true" />
                                <img :src="collectedIcon" v-if="!false" />  -->
                                <img v-if="goods.is_shou_chang == 1" :src="require('img/alibaba/collect-base.png')" />
                                <img style=" border-radius: 50%; padding: 4px 3px" v-if="goods.is_shou_chang == 0" :src="require('img/alibaba/collect-goods.png')" /></span>收藏
                        </div>
                    </div>
                    <div class="goods-row row2" >
                        <div class="price">
                            您的价格：
                            <span v-if="logined" v-text="getMemberPrice(goods)"></span>
                            <span v-if="!logined">会员可见</span>
                        </div><br>

                	<!--       <div v-if="!logined" style="margin-top: -30px;">
						   <block id="">
                       	库存：
                       </block>
                       <div class="stock" style="text-align:right;color: red; font-weight: bold;text-align: right;
    color: red;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 5px;" v-if="goods.goods_stock"><div class="text-right"></div> 会员可见 </div>
                       <div class="stock" style="text-align:right;color: red; font-weight: bold;text-align: right;
    color: red;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 5px;" v-else-if="goods.stock"><div class="text-right"></div> 会员可见</div>
                        <div class="stock" style="text-align:right;color: red; font-weight: bold;text-align: right;
    color: red;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 5px;"  v-else><div class="text-right"></div> 会员可见</div>
                       </div> -->
                                              
                    </div>

                  <div class="goods-row row2" v-if="logined">
                    <div class="stock" style="text-align:right;width: unset !important;" v-if="goods.goods_stock"><div class="text-right"></div> 库存：{{ goods.goods_stock }}</div>
                    <div class="stock" style="text-align:right;width: unset !important;" v-else-if="goods.stock"><div class="text-right"></div> 库存：{{ goods.stock }}</div>
                    <div class="stock" style="text-align:right;width: unset !important;"  v-else><div class="text-right"></div> 库存:&#8194; 0</div>
                  </div>

                </div>
            </div>
        </div>
        <div class="jump">
            <el-pagination class="jump-count" v-if="showpage" @current-change="handleChange()" :current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" ref="pagination" :total="data.count"> </el-pagination>
            <el-button v-if="showpage" type="info" size="mini" class="sure">确定</el-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {addCollect} from "@/api/goods/goods_collect";

export default {
    name: "goods-list",
    props: {
        data: {
            type: Object,
            stock: "" //库存数量
        },
        pagesize: {
            type: Number
        },
        page: {
            type: Number
        },
        showpage: {
            type: Boolean,
            default: function () {
                return true
            }
        }
    },
    computed: {
        ...mapGetters(["member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    },
    data() {
        return {
            collectIcon: require("img/alibaba/collect-goods.png"),
            collectedIcon: require("img/alibaba/collect-goods-s.png"),
            currentPage: 0
        }
    },
    mounted() {
        this.currentPage = this.page
    },
    methods: {
        getMemberPrice (goods) {
            let m1 = (goods.cost_price*(goods.consume_discount/100)).toFixed(2)
            if (goods.member_price) {
                return '￥' + goods.member_price
            }
            if (!isNaN(m1) && goods.is_consume_discount) {
                return '￥' + m1;
            }
            return '￥' + goods.cost_price
        },
        handleChange() {
            this.$emit("syncGoodsList", this.currentPage)
            // console.log(this.page)
        },
        editCollection(goods, index) {
          console.log()
          //未关注添加关注
          if (goods.is_shou_chang == 0) {
            addCollect({
              sku_id: goods.sku_id,
              goods_id: goods.goods_id
            }).then(res => {
              var data = res.data
              if (data > 0) {
                this.$message('收藏成功')
                this.whetherCollection = 1
                this.data.list[index].is_shou_chang = 1;
              }
              // console.log(data)
            }).catch(err => {
              if (err.message == '您尚未登录，请先进行登录') {
                this.$router.push('/login');
              }
              this.btnSwitch = false
            })
          } else {
            //已关注取消关注
            this.$message('收藏成功')
          }
        }
    }
}
</script>

<style  lang="scss">
.goods-list {
    .goods-item {
        width: 230px;
        margin: 0 24px 24px 0;
        background-color: $base-color-gray;
        float: left;

        &:nth-child(6n) {
            margin-right: 0;
        }

        .el-image {
            width: 100%;
            height: 230px;
            box-sizing: border-box;
            border: 1px solid #d9d9d9;
            cursor: pointer;

            img {
                transform: scale(1);
                transition: all 0.3s;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .goods-info {
            padding: 0 10px 10px;

            .goods-row {
                margin-bottom: 5px;
                display: flex;
                align-items: flex-start;

                &.row2 {
                    div {
                        color: $ns-text-color-gray;
                        font-size: $ns-font-size-base;

                        &.price {
                            width: 63%;
                        }

                        &.stock {
                            width: 40%;
                        }
                    }

                    span {
                        color: red;
                        font-weight: bold;
                    }
                }
            }

            h2 {
                width: 100px;
                font-size: 16px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                padding-right: 60px;
                font-weight: 400;
            }

            .goods-collect {
                display: flex;
                align-items: center;
                font-size: $ns-font-size-base;
                font-weight: bold;
                color: $ns-text-color-black;

                .goods-collect-btn {
                    width: 15px;
                    height: 15px;
                    border: 1px solid $ns-text-color-gray;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 5px;

                    img {
                        width: 11px;
                        height: 9px;
                    }
                }

                &.activited {
                    color: $base-color;
                    font-weight: 400;

                    .goods-collect-btn {
                        // background-color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
        }
    }
    .el-pagination {
        text-align: center;
    }
}
.jump {
    // text-align: right;
    margin-top: 20px;
    .jump-count {
        // padding-right: 20px;
        float: left;
        margin-left: 34%;
    }

    .sure {
        margin: 0px 10px;
        font-size: 13px;
        padding: 7px 10px;
        height: 28px;
        border-radius: 3px;
        background-color: #fff;
        color: #606266;
        line-height: 13px;
        margin-top: 2px;
        border: 1px solid #dcdfe6;
        // border: none;
    }
}
.stock {
    // text-align: right;
}
.price span {
    margin-left: -13px;
}
.price {
}
.text-right {
} 
</style>
